<template>
  <hero-page hero="../../assets/images/AboutHero.png" title="Terms and Conditions">
    <template v-slot> <pdf :src="src" v-for="i in numPages" :key="i" :page="i"></pdf> </template>
  </hero-page>
</template>

<script>
import HeroPage from "../../components/Common/HeroPage";
import Landing from "@/layouts/Landing";
import pdf from "vue-pdf";
import config from "../../config";

let loadingTask = pdf.createLoadingTask(`${config.frontend}/static/tectechain-terms-conditions.pdf`);

export default {
  name: "Privacy",
  components: {
    HeroPage,
    pdf
  },
  data() {
    return {
      src: loadingTask,
      numPages: null
    }
  },
  mounted() {
    this.$emit(`update:layout`, Landing);
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
  },
  computed: {
    privacySrc() {
      return `${config.frontend}/static/tectechain-terms-conditions.pdf`;
    }
  }
};
</script>
